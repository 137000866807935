import router from './router'
const whiteList = ['/page1','/page2','/page3','/page4','/page5'] // 不重定向白名单
router.beforeEach((to, from, next) => {
    if (whiteList.indexOf(to.path) !== -1) {
      next('/')
    } else {
      next()
  }
})

router.afterEach(() => {
})
