
import Vue from 'vue'
import { fetchTypeList } from '@/api/prod';
export default Vue.extend({
    name: 'Industrial',
    data() {
        return {
            prodLList:[],
            prodRList:[],
            prodCursor:0 //放入数组的游标
        }
    },
    created() {
        this.getProdType()
    },
    methods: {
        enterProduct(index:any){
            this.$router.push({ path: 'Product',query:{ typeId: index}})
        },
        getProdType(){
            fetchTypeList().then(response => {
                response.data.forEach(element => {
                    if(this.prodCursor<2){
                        this.prodLList.push(element)
                    }else{
                        this.prodRList.push(element)
                    }
                    this.prodCursor++
                });
            })
        },
    },
})
