
import Vue from 'vue'
import { fetchTypeList,fetchNewsByType } from '@/api/news';
import dayjs from "dayjs";
//导入js
import Swiper from 'swiper';
//引入css
import "swiper/css/swiper.min.css";
export default Vue.extend({
  name: 'NewsView',
  data() {
    return {
      newsMenuIndex: 0,
      //新闻资讯 先捕獲菜單然后根据捕获来的菜单[0]获取该Type的新闻内容
      newsMenu:[],
      newsInfo:[],
    }
  },
  mounted() {
    let vm = this
    //新闻
    var news = new Swiper('.news_right .swiper-container', {
      // 如果需要滚动条
      scrollbar: '.swiper-scrollbar',
      slidesPerView: 4,
      observer:true, //启动动态检查器(OB/观众/观看者)，当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper。
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      spaceBetween: 20,
      duration: 3000,
      slidesPerGroup: 4,
      pagination: {
        el: ".news_right .swiper-pagination",
        type: "fraction"
      },
      navigation: {
        nextEl: ".news_right .swiper-button-next",
        prevEl: ".news_right .swiper-button-prev"
      },
    })
    news.on('click', function () {
      var clickedSlide = this.clickedSlide;
      var index = clickedSlide.getAttribute('data-index');
      vm.$router.push({ path: 'news/NewsDetail', query: { newsId: index } })
    });
  },
  created() {
    this.getNewsType()
  },
  methods: {
    getNewsType(){
      fetchTypeList( {keyword: '', pageSize: 8, pageNum: 1}).then(response => {
        this.newsMenu = response.data.list;
        this.newsMenuIndex=this.newsMenu[0].id;
        this.getNewsListByType(this.newsMenu[0].id)
    })
    },
    getNewsListByType(newsType){
      fetchNewsByType( {newsType: newsType, pageSize: 8, pageNum: 1}).then(response => {
        this.newsInfo = response.data.list;
    })    
    },
    selectNewsType(item) {
      this.newsMenuIndex = item.id
      this.getNewsListByType(item.id)
    },
    goNewsList() {
      this.$router.push({ path: 'news' })
    },
    getDateOfDay(item){
      return dayjs(item.createdTime).format("YYYY-MM-DD").split('-')[2]
    },
    getMonthYear(item){
    // let year = dayjs(item.createdTime).format("YYYY-MM-DD");
      // return year.substring(0, year.lastIndexOf('-'))
      return dayjs(item.createdTime).format("YYYY-MM-DD").split('-')[1]
    }
  }
})
