
import Vue from 'vue'
import { WOW } from 'wowjs'
import History from "@/views/home/history.vue";
import NewsView from "@/views/home/news.vue";
// import Paper from "@/views/home/Paper.vue";
import Industrial from "@/views/home/Industrial.vue";
import AboutUs from '../views/home/AboutUs.vue'
import Foot from './home/Foot.vue'
export default Vue.extend({
  name: 'HomeView',
  components: {
    History, NewsView, AboutUs, Industrial, Foot
  },
  data() {
    return {
      options: {
        // licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        // 是否显示导航，默认为false

        anchors: ['page1', 'page2', 'page3', 'page4', 'page5'], // 注意不带#
        menu: '#menu', // 绑
        recordHistory: true,
        afterLoad(anchorLink, page) {
          let pageIconNumber = document.getElementsByClassName("pageIcon") as HTMLCollectionOf<HTMLElement>
          for (let i = 0; i < pageIconNumber.length; i++) {
            if (page.index == i) {
              pageIconNumber[i].style.background = "#49b24c"
            } else {
              pageIconNumber[i].style.background = "transparent"
            }
          }
          if (page.index == 0) {
            document.getElementsByClassName('header')[0].classList.remove('header_c')
            document.getElementsByClassName('inav')[0].classList.remove('inav_c')
          } else if (page.index == 1) {
            document.getElementsByClassName('header')[0].classList.add('header_c')
            document.getElementsByClassName('inav')[0].classList.add('inav_c')
          }
          else if (page.index == 2) {
            document.getElementsByClassName('header')[0].classList.add('header_c')
            document.getElementsByClassName('inav')[0].classList.add('inav_c')
          }
          else if (page.index == 3) {
            document.getElementsByClassName('header')[0].classList.add('header_c')
            document.getElementsByClassName('inav')[0].classList.add('inav_c')
          } else if (page.index == 4) {
            document.getElementsByClassName('header')[0].classList.remove('header_c')
            document.getElementsByClassName('inav')[0].classList.remove('inav_c')
          }
        },
        scrollBar: true,
        afterRender: function () {
          var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 40,
            mobile: true,
            live: true,
          });
          wow.init();
        },
      },
      menuList: [
        { label: '首页', value: 'page1' },
        { label: '关于天一', value: 'page2' },
        { label: '产业布局', value: 'page3' },
        { label: '新闻中心', value: 'page4' },
        { label: '页脚', value: 'page5' },
      ],
      menuIndex: 0,
    }
  },
  mounted() {
    // this.$refs.fullpage.setLockAnchors(true)
  },

  methods: {
    handleMove(index: any) {
      this.menuIndex = index
      switch (index) {
        case 0:
          this.$refs.fullpage.api.moveTo(1, 0)
          break
        case 1:
          this.$refs.fullpage.api.moveTo(2, 1)
          break
        case 2:
          this.$refs.fullpage.api.moveTo(3, 2)
          break
        case 3:
          this.$refs.fullpage.api.moveTo(4, 3)
          break
        case 4:
          this.$refs.fullpage.api.moveTo(5, 4)
          break
      }
    },
  }
})
