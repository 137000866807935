(function flexible(window, document) {
    // 获取的html 的根元素
    var docEl = document.documentElement;
    // dpr 物理像素比
    var dpr = window.devicePixelRatio || 1;
   
    // adjust body font size    设置body 的字体大小
    function setBodyFontSize() {
      // 如果页面中有body 这一个元素，就设置body大小
      if (document.body) {
        document.body.style.fontSize = 12 * dpr + "px";
      } else {
        // 如果页面中没有body 这个元素，则等页面中主要的DOM元素加载完毕后，再设置body的字体大小
        document.addEventListener("DOMContentLoaded", setBodyFontSize);
      }
    }
    setBodyFontSize();
   
    // set 1rem = viewWidth / 10    设置html 元素的字体大小
    function setRemUnit() {
      // rem  把html分成24等份
      var rem = docEl.clientWidth / 24;
      docEl.style.fontSize = rem + "px";
    }
   
    setRemUnit();
   
    // reset rem unit on page resize    当页面尺寸大小发生变化的时候，要重新设置一下rem 的大小
    window.addEventListener("resize", setRemUnit);
    // pageshow 重新加载页面触发的事件
    window.addEventListener("pageshow", function(e) {
      // e.persisted 返回的事true 就是说如果这个页面事从缓存取过来的页面，也需要重新计算一下rem 的大小
      if (e.persisted) {
        setRemUnit();
      }
    });
   
    // detect 0.5px supports    用来处理有些移动端的浏览器不支持0.5像素的写法
    if (dpr >= 2) {
      var fakeBody = document.createElement("body");
      var testElement = document.createElement("div");
      testElement.style.border = ".5px solid transparent";
      fakeBody.appendChild(testElement);
      docEl.appendChild(fakeBody);
      if (testElement.offsetHeight === 1) {
        docEl.classList.add("hairlines");
      }
      docEl.removeChild(fakeBody);
    }
  })(window, document);
   