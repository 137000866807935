
import Vue from 'vue'
//导入js
import Swiper from 'swiper';
//引入css
import "swiper/css/swiper.min.css";
import { fetchHistoryList, fetchCompanyIntro } from '@/api/aboutUs';

export default Vue.extend({
    name: 'History',
    data() {
        return {
            //swiper公司介绍
            companyIntroduce: "",
            companyProfileList: [],
        }
    },
    created() {
        this.getCompanyIntro()
        this.getHistoryList()
    },
    methods: {
        getCompanyIntro() {
            fetchCompanyIntro().then(response => {
                this.companyIntroduce = response.data.introduce
            })
        },
        getHistoryList() {
            fetchHistoryList().then(response => {
                let Histories = response.data.list
                Histories.forEach(el => {
                    const Arr = el.yearDesc.split(';')
                    this.companyProfileList.push({
                        year: el.enterpriseYear,
                        progressList: Arr
                    });
                })
            })
        }
    },
    mounted() {
        //公司历史
        var mySwiper1 = new Swiper('.ihistory', {
            loop: true,
            slidesPerView: 4,
            observer: true,
            spaceBetween: 0,
            speed: 1000,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.ihistory_box .swiper-button-next',
                prevEl: '.ihistory_box .swiper-button-prev',
            },
            breakpoints: {
                999: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                }
            }
        })
    },
})
